import { GatewayInfoSocieteOrias } from "@conformite/gateway";
import { Link } from "@src/components/Link/Link";
import { useEntreprises } from "@src/store/store.entreprise";
import { useMe } from "@src/store/store.me";
import { intersection } from "lodash";

const usePub = () => {
  const { me } = useMe();
  const { entreprisesBySiren } = useEntreprises();
  if (!me || !entreprisesBySiren)
    return { isAvailableToAPIC: false, isAvailableToCSCA: false };

  const isAvailableToAPIC = Object.values(entreprisesBySiren).some(
    (entreprise) => {
      const isIobsp =
        intersection(GatewayInfoSocieteOrias.CategorieIOBSPList, [
          ...entreprise.categoriesActivites,
          ...entreprise.categoriesActivitesAccessoires,
        ]).length > 0;

      const isNotAlreadyAPIC = entreprise.organisationProfessionnelle.every(
        (orga) =>
          orga !== GatewayInfoSocieteOrias.OrganisationProfessionnelle.APIC
      );

      return isIobsp && isNotAlreadyAPIC && !!entreprise.numeroOrias;
    }
  );

  const isAvailableToCSCA = Object.values(entreprisesBySiren).some(
    (entreprise) => {
      const isCOA = [
        ...entreprise.categoriesActivites,
        ...entreprise.categoriesActivitesAccessoires,
      ].includes(GatewayInfoSocieteOrias.Categorie.COA);

      const isNotAlreadyCSCA = entreprise.organisationProfessionnelle.every(
        (orga) =>
          orga !==
          GatewayInfoSocieteOrias.OrganisationProfessionnelle.PLANETE_CSCA
      );

      return (
        isCOA &&
        isNotAlreadyCSCA &&
        !!entreprise.numeroOrias &&
        entreprise.chiffreAffaire < 300_000
      );
    }
  );

  return { isAvailableToAPIC, isAvailableToCSCA };
};

export function EntreprisePaymentPublicity() {
  const { isAvailableToAPIC, isAvailableToCSCA } = usePub();

  if (isAvailableToCSCA)
    return (
      <Link
        target="_blank"
        to="https://adhesion.planetecsca.fr/adherer/offre-speciale-endya/"
      >
        <img
          width="100%"
          src="/adhesion-csca-2025.webp"
          alt="Mon adhésion CSCA offerte"
        />
      </Link>
    );
  if (isAvailableToAPIC)
    return (
      <Link
        target="_blank"
        to="https://www.apicfrance.asso.fr/adherent/devenir-adherent.html"
      >
        <img
          width="100%"
          src="/adhesion-apic-2025.webp"
          alt="Mon adhésion APIC offerte"
        />
      </Link>
    );

  return null;
}
