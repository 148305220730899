import {
  GatewayEntreprise,
  GatewayInfoSocietePappers,
} from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputMask,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  Emphasis,
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayEntrepriseApi } from "@src/api/entreprise2.api";
import { GatewayInfoSocietePappersApi } from "@src/api/info-societe-pappers.api";
import { GatewaySearchExistingEntreprisesAPI } from "@src/api/search-existing-entreprise.api";
import { handleGatewayError } from "@src/api/utils/handleGatewayError";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { userSession } from "@src/session/UserSession";
import { Me } from "@src/store/store.definition";
import { useMe } from "@src/store/store.me";
import { ReactNode, useCallback, useState } from "react";
import style from "./EntrepriseSirenForm.module.scss";

function splitEveryN(str: string, n: number) {
  const arr = [];

  for (let index = 0; index < str.length; index += n) {
    arr.push(str.slice(index, index + n));
  }

  return arr;
}

export enum EntrepriseSirenError {
  ACTIVE = "ACTIVE",
  RADIE = "RADIE",
  CAPTCHA = "CAPTCHA",
  INCONNUE = "INCONNUE",
  NON_TROUVE = "NON_TROUVE",
}

export const EntrepriseSirenSchema = zod.object({
  siren: zod
    .string()
    .length(11, "Numéro Siren incorrect")
    .transform((value) => value.replaceAll(" ", "")),
});

export type EntrepriseSirenFormValues = zod.infer<typeof EntrepriseSirenSchema>;

type EntrepriseSirenFormProps = {
  isEdit?: boolean;
  fromParcoursAdhesion?: boolean;
  setIsLoading: (isLoading: boolean) => void;
  executeAfterSubmit: (
    responsePappers?: GatewayInfoSocietePappers.GetInfoSocietePappersResponse
  ) => void;
  defaultValues: { siren?: string };
  formId?: string;
  overrideErrorMessage?: { onExisting: string; onRadie: ReactNode };
};

const defaultMessage: NonNullable<
  EntrepriseSirenFormProps["overrideErrorMessage"]
> = {
  onExisting: "Cette entreprise existe déja",
  onRadie: (
    <span>
      Cette entreprise est radiée. Nous vous invitons à nous contacter à
      <a href="mailto:daisy.facchinetti@endya.fr">daisy.facchinetti@endya.fr</a>
    </span>
  ),
};

export function EntrepriseSirenForm({
  setIsLoading,
  executeAfterSubmit,
  defaultValues,
  formId,
  fromParcoursAdhesion,
  isEdit,
  overrideErrorMessage = defaultMessage,
}: EntrepriseSirenFormProps) {
  const [statusEntreprise, setStatusEntreprise] = useState<
    EntrepriseSirenError | undefined
  >(undefined);
  const { me } = useMe();

  const formConfig = useFormConfig<
    typeof EntrepriseSirenSchema,
    EntrepriseSirenFormValues
  >({
    schema: EntrepriseSirenSchema,
    defaultValues: {
      siren:
        defaultValues.siren !== undefined && defaultValues.siren.length === 9
          ? splitEveryN(defaultValues.siren, 3).join(" ")
          : defaultValues.siren,
    },
  });

  const retrieveEntrepriseForParcoursAdhesion = useCallback<
    (
      siren: string,
      me: Me | undefined
    ) => Promise<GatewayEntreprise.EntrepriseStatusResponse>
  >(
    async (siren: string) => {
      let hasToSearchStatus = true;
      if (userSession.sessionData.isConnected && me?.entreprisePrincipale) {
        const existingEntreprises =
          await GatewaySearchExistingEntreprisesAPI.bySirensExcludingPrincipale(
            {
              sirens: [siren],
            }
          );
        hasToSearchStatus = existingEntreprises.length > 0;
      }

      if (hasToSearchStatus) {
        return GatewayEntrepriseApi.getEntrepriseStatus(siren);
      }

      return GatewayEntreprise.EntrepriseStatusResponse.INEXISTANTE;
    },
    [me?.entreprisePrincipale]
  );

  const handleSubmit = async (submittedData: EntrepriseSirenFormValues) => {
    if (isEdit) {
      executeAfterSubmit(undefined);
      return;
    }
    setIsLoading(true);
    let entrepriseStatus: GatewayEntreprise.EntrepriseStatusResponse =
      GatewayEntreprise.EntrepriseStatusResponse.INEXISTANTE;
    try {
      entrepriseStatus = fromParcoursAdhesion
        ? await retrieveEntrepriseForParcoursAdhesion(submittedData.siren, me)
        : await GatewayEntrepriseApi.getEntrepriseStatus(submittedData.siren);
    } catch (e) {
      console.error(e);
      handleGatewayError({
        onCaptchaError: (err) => {
          if (err.name === "CaptchaRefused")
            setStatusEntreprise(EntrepriseSirenError.CAPTCHA);
          else setStatusEntreprise(EntrepriseSirenError.INCONNUE);
        },
        onUnhandled: () => setStatusEntreprise(EntrepriseSirenError.INCONNUE),
      })(e);
      setIsLoading(false);
      return;
    }
    try {
      if (
        entrepriseStatus === GatewayEntreprise.EntrepriseStatusResponse.RADIE
      ) {
        setStatusEntreprise(EntrepriseSirenError.RADIE);
        return;
      }
      if (
        entrepriseStatus !==
          GatewayEntreprise.EntrepriseStatusResponse.INEXISTANTE &&
        entrepriseStatus !==
          GatewayEntreprise.EntrepriseStatusResponse.NON_ADHERENT
      ) {
        setStatusEntreprise(EntrepriseSirenError.ACTIVE);
        return;
      }
      const responsePappers = await GatewayInfoSocietePappersApi.getBySiren(
        submittedData.siren
      );

      executeAfterSubmit(responsePappers);
    } catch (e) {
      console.error(e);
      handleGatewayError({
        onCaptchaError: (err) => {
          if (err.name === "CaptchaRefused")
            setStatusEntreprise(EntrepriseSirenError.CAPTCHA);
          setStatusEntreprise(EntrepriseSirenError.INCONNUE);
        },
        onResponse: (err) => {
          if (err.response.status === 404)
            setStatusEntreprise(EntrepriseSirenError.NON_TROUVE);
          else setStatusEntreprise(EntrepriseSirenError.INCONNUE);
        },
        onUnhandled: () => setStatusEntreprise(EntrepriseSirenError.INCONNUE),
      })(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form formConfig={formConfig} onSubmit={handleSubmit} id={formId}>
      <FormRow>
        <FormField className={style.indicationRequired}>
          <Emphasis>*</Emphasis> Champs obligatoires
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <InputMask<EntrepriseSirenFormValues>
            mask="999 999 999"
            name="siren"
            label="Numéro Siren"
            required
          />
        </FormField>
      </FormRow>

      {statusEntreprise === EntrepriseSirenError.ACTIVE && (
        <FormRow>
          <FormField>
            <InfoCard type="warning">
              {overrideErrorMessage.onExisting}
            </InfoCard>
          </FormField>
        </FormRow>
      )}

      {statusEntreprise === EntrepriseSirenError.RADIE && (
        <FormRow>
          <FormField>
            <InfoCard type="error">{overrideErrorMessage.onRadie}</InfoCard>
          </FormField>
        </FormRow>
      )}

      {statusEntreprise === EntrepriseSirenError.CAPTCHA && (
        <FormRow>
          <FormField>
            <InfoCard type="error">
              Vous êtes identifié comme étant un robot, merci de nous contacter
              si cela est une erreur
            </InfoCard>
          </FormField>
        </FormRow>
      )}
      {statusEntreprise === EntrepriseSirenError.NON_TROUVE && (
        <FormRow>
          <FormField>
            <InfoCard type="error">
              Nous n&apos;avons pas pu trouver cette entreprise
            </InfoCard>
          </FormField>
        </FormRow>
      )}
      {statusEntreprise === EntrepriseSirenError.INCONNUE && (
        <FormRow>
          <FormField>
            <InfoCard type="error">Une erreur inconnue est survenue</InfoCard>
          </FormField>
        </FormRow>
      )}
    </Form>
  );
}
