import {
  Button,
  Card,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as IconInfo } from "@src/assets/icons/info-circle-fill.svg";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { Link } from "@src/components/Link/Link";
import styles from "./cardRenouvellement.module.scss";

export function CardRenouvellement({ millesime }: { millesime: number }) {
  return (
    <Card className={styles.card}>
      <FormGrid>
        <FormRow>
          <FormField>
            <div className={styles.grid}>
              <div className={styles.gridLeft}>
                <h3 className={styles.title}>
                  <IconInfo className={styles.icon} /> Votre adhésion touche à
                  sa fin.
                </h3>
                <p className={styles.text}>
                  Elle a pris fin le 31 décembre 2024. Le renouvellement
                  d&apos;adhésion doit être effectué pour votre immatriculation
                  à l&apos;Orias.
                </p>
              </div>
              <div className={styles.gridRight}>
                <Link to="/renouvellement/">
                  <Button variant="outline-accent">
                    Renouveler mon adhésion
                  </Button>
                </Link>
              </div>
            </div>
          </FormField>
        </FormRow>
        {millesime === 2023 && (
          <FormRow>
            <FormField>
              <InfoCard type="warning" className={styles.infoCard2023}>
                Si vous souhaitez renouveler votre adhésion pour 2024, il
                n&apos;est pas nécessaire de compléter les axes de conformité
                pour l&apos;année 2023.
              </InfoCard>
            </FormField>
          </FormRow>
        )}
      </FormGrid>
    </Card>
  );
}
