import {
  GatewayEntreprise,
  GatewayInfoSocietePappers,
} from "@conformite/gateway";
import {
  FormField,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ParcoursAdhesionNextAction } from "@src/adhesion/AdhesionActions/ParcoursAdhesionNextAction";
import { AdhesionData } from "@src/adhesion/AdhesionData/adhesionData";
import {
  AdhesionStepEnum,
  ID_FORM_ADHESION,
  ParcoursAdhesionStepComponentProps,
} from "@src/adhesion/ParcoursAdhesion.definition";
import { ReactComponent as Loader } from "@src/assets/loader.svg";
import { InfoCard } from "@src/components/InfoCard/InfoCard";
import { ParcoursFullPageTemplate } from "@src/parcours/Template/ParcoursFullPageTemplate";
import { EntrepriseSirenForm } from "@src/societes/form/EntrepriseSirenForm";
import { useState } from "react";

function extractTypeSouscripteur(isPersonneMorale?: boolean) {
  if (isPersonneMorale === undefined) return undefined;
  return isPersonneMorale
    ? GatewayEntreprise.TypeSouscripteur.PERSONNE_MORALE
    : GatewayEntreprise.TypeSouscripteur.PERSONNE_PHYSIQUE;
}

function infoSocietePappersToAdhesionData(
  infoSociete: GatewayInfoSocietePappers.GetInfoSocietePappersResponse
): Partial<AdhesionData> {
  return {
    entreprise: {
      siren: infoSociete.siren,
      nonDiffusable: !infoSociete.diffusable,
      raisonSociale: infoSociete.raisonSociale ?? undefined,
      formeJuridique: infoSociete.formeJuridique ?? undefined,
      trancheEffectif: infoSociete.trancheEffectif ?? undefined,
      typeSouscripteur: extractTypeSouscripteur(infoSociete.isPersonneMorale),
    },
    entrepriseAdresse: {
      pays: infoSociete.adresse?.pays ?? undefined,
      codePostal: infoSociete.adresse?.codePostal ?? undefined,
      ville: infoSociete.adresse?.ville ?? undefined,
      typeVoie: infoSociete.adresse?.typeVoie ?? undefined,
      numeroVoie: infoSociete.adresse?.numeroVoie ?? undefined,
      libelleVoie: infoSociete.adresse?.libelleVoie ?? undefined,
      complementAdresse: infoSociete.adresse?.complementAdresse ?? undefined,
    },
  };
}

export function AdhesionSirenPage({
  changeStep,
  data,
  addData,
}: ParcoursAdhesionStepComponentProps) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ParcoursFullPageTemplate
      title="Identification"
      actions={
        <ParcoursAdhesionNextAction
          type="submit"
          icon={isLoading ? <Loader /> : undefined}
          disabled={isLoading}
        >
          Suivant
        </ParcoursAdhesionNextAction>
      }
    >
      <FormRow>
        <FormField>
          <InfoCard type="info">
            La personne qui crée le compte devient administrateur. Si vous
            représentez un groupe ou un réseau, identifiez dans un premier temps
            la société qui administre le groupe ou le réseau.
          </InfoCard>
        </FormField>
      </FormRow>
      <FormRow>
        <FormField>
          <EntrepriseSirenForm
            fromParcoursAdhesion
            setIsLoading={setIsLoading}
            executeAfterSubmit={(responsePappers) => {
              if (!responsePappers) throw new Error("No response pappers");
              addData(infoSocietePappersToAdhesionData(responsePappers));
              changeStep(AdhesionStepEnum.ENTREPRISE);
            }}
            defaultValues={{ siren: data.entreprise.siren }}
            formId={ID_FORM_ADHESION}
            overrideErrorMessage={{
              onExisting:
                "Vous êtes déjà adhérent chez Endya, merci de vous connecter",
              onRadie: (
                <span>
                  Nous ne pouvons vous inscrire. Nous vous invitons à nous
                  contacter à{" "}
                  <a href="mailto:daisy.facchinetti@endya.fr">
                    daisy.facchinetti@endya.fr
                  </a>
                </span>
              ),
            }}
          />
        </FormField>
      </FormRow>
    </ParcoursFullPageTemplate>
  );
}
